<template>
  <div class="mobile-menu-main">
    <nav class="mobile-menu-main__navigation">
      <transition
        name="mobile-menu-main-list-transition"
        mode="out-in"
        @enter="focusOnFirstListElement"
      >
        <ul v-if="!activeItem" ref="activeList" class="mobile-menu-main__list">
          <li
            v-for="(item, itemIdx) in fields?.navItems"
            :key="item.fields?.title?.value"
            class="mobile-menu-main__list-item"
          >
            <component
              v-bind="getLinkBindings(item.fields?.link)"
              :is="getLinkTag(item.fields?.link?.value?.linktype)"
              v-if="!item.fields.columns?.length"
              class="no-underline"
              :class="listItemClasses"
              :data-test-id="`mobile-nav-1lvl-${itemIdx}`"
              @click="onNavigationItemClick(item?.fields?.title?.value, 'main_header_top_level')"
            >
              {{ item.fields?.title?.value }}
            </component>
            <span v-else class="mobile-menu-main__list-btn-wrapper">
              <button
                :class="listItemClasses"
                :aria-label="`Show ${item.fields.title.value.toLowerCase()} submenu`"
                :aria-expanded="isSubItemMenuExpanded"
                aria-controls="mobile-menu-main-sublist"
                :data-test-id="`mobile-nav-1lvl-${itemIdx}`"
                @click="openSubMenu($event, item)"
              >
                <span>{{ item.fields?.title?.value }}</span>
                <i class="icon icon-Forward" aria-hidden="true"></i>
              </button>
            </span>
          </li>
        </ul>
        <ul v-else id="mobile-menu-main-sublist" ref="activeList" class="mobile-menu-main__list">
          <li class="mobile-menu-main__list-item">
            <span
              class="mobile-menu-main__list-btn-wrapper mobile-menu-main__list-btn-wrapper--back"
            >
              <button
                :class="listItemClasses"
                data-test-id="go-back-to-the-main-menu-btn"
                @click="goBack"
              >
                <i class="icon icon-Back" aria-hidden="true"></i>
                <span>{{ activeItem.fields?.title?.value }}</span>
              </button>
            </span>
          </li>
          <li
            v-if="isFeaturedItem && activeItem.fields?.featuredItemIsOnTop?.value"
            class="mobile-menu-main__featured-item"
          >
            <featured-item :fields="activeItem.fields" />
          </li>
          <li v-if="activeItem.fields?.columns?.length">
            <template v-for="(column, index) in activeItem.fields.columns">
              <ul
                v-if="
                  column.fields?.subItems?.length &&
                  (!isFeaturedItem || (isFeaturedItem && index < 2))
                "
                :key="column.fields?.title"
                class="mobile-menu-main__nav-item-list"
              >
                <span
                  v-if="column.fields?.title?.value"
                  class="mobile-menu-main__nav-item-list-heading"
                  :class="listItemClasses"
                >
                  {{ column.fields.title.value }}
                </span>
                <li v-for="item in column.fields.subItems" :key="item.fields.title">
                  <nav-item
                    v-bind="item.fields"
                    :item-type="
                      activeItem.fields?.showLinkImages?.value ? 'image-left' : 'text-only'
                    "
                  />
                </li>
              </ul>
            </template>
          </li>
          <li
            v-if="isFeaturedItem && !activeItem.fields?.featuredItemIsOnTop?.value"
            class="mobile-menu-main__featured-item"
          >
            <featured-item :fields="activeItem.fields" />
          </li>
        </ul>
      </transition>
    </nav>
    <div class="mobile-menu-main__btn-wrapper">
      <base-button
        v-if="fields?.ctaHeaderLink?.value?.href"
        class="mobile-menu-main__btn"
        :variant="brandName !== 'PubPartners' ? 'secondary' : 'primary'"
        type="link"
        :link="fields?.ctaHeaderLink?.value"
        data-test-id="header-cta-mobile-button"
        :label="t('header-cta-text-short')"
        @click="onNavigationItemClick(t('header-cta-text-short'), 'main_header')"
      />
      <base-button
        v-for="(option, name) in bookingOptions"
        v-else
        :key="option.key"
        class="mobile-menu-main__btn"
        variant="secondary"
        :type="name === CTA_BOOK_TABLE ? 'link' : null"
        :link="name === CTA_BOOK_TABLE ? option.data : null"
        :label="t(option.labelLong)"
        :data-test-id="`${option.key}-mobile-button`"
        @click="onBookingItemClick(name, t(option.labelLong))"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '../../../../stores/appStore';
import { getLinkTag, getLinkBindings } from '../../../../utils/linkTransformer';
import BaseButton from '../../../2_elements/BaseButton/BaseButton.vue';
import NavItem from '../../NavItem/NavItem.vue';
import FeaturedItem from '../../FeaturedItem/FeaturedItem.vue';

const CTA_BOOK_TABLE = 'ctaBookTable';
const CTA_BOOK_ROOM = 'ctaBookRoom';

const { t } = useI18n();
const appStore = useAppStore();

const { handleBookingClick, handleNavigationItemClick } = inject('dataLayerFunctions');
const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  bookingOptions: {
    type: Object,
    default: () => ({}),
  },
});

const activeItem = ref(null);
const isSubItemMenuExpanded = ref(false);
const activeList = ref(null);

const isFeaturedItem = computed(() => activeItem.value?.fields?.featuredItemEnabled?.value);

const listItemClasses = computed(() => ({
  'font-s': ['HungryHorse', 'ChefBrewer'].includes(brandName),
}));

const openSubMenu = (event, item) => {
  isSubItemMenuExpanded.value = true;

  setTimeout(() => {
    event.preventDefault();
    activeItem.value = item;
  }, 100);
};

const goBack = () => {
  isSubItemMenuExpanded.value = false;
  activeItem.value = null;
};

const focusOnFirstListElement = () => {
  activeList.value?.querySelector('button')?.focus();
};

const onNavigationItemClick = (name, component) => {
  handleNavigationItemClick(name, component);
};

const onBookingItemClick = (name, label) => {
  if (name === CTA_BOOK_ROOM)
    appStore.setGuestlineActiveUrl(props.bookingOptions[CTA_BOOK_ROOM].guestlineUrl);
  handleBookingClick(label);
};
</script>

<style lang="scss">
@import './scss/MobileMenuMain.scss';
</style>
