<template>
  <section class="featured-item">
    <div class="featured-item__img">
      <base-image
        class="featured-item__pic"
        :desktop-url="fields.featuredItemCoverImage.value.src"
        :desktop-alt="fields.featuredItemCoverImage.value.alt"
        :custom-params="{
          fullWidthDesktop: 610,
          desktop: numOfColumns === 1 ? 840 : 610,
          tablet: 960,
          mobile: 715,
          mobileS: 360,
        }"
        :fallback-param="{
          fullWidthDesktop: 's',
          desktop: numOfColumns === 1 ? 'w' : 's',
        }"
        full-width
        loading="lazy"
        fetchpriority="low"
      />
    </div>
    <div class="featured-item__content var-dark">
      <sc-text tag="p" class="featured-item__heading font-m" :field="fields.featuredItemHeading" />
      <sc-text tag="p" class="featured-item__description" :field="fields.featuredItemDescription" />
      <base-button
        variant="secondary"
        type="link"
        class="featured-item__cta"
        :link="fields.featuredItemCta.value"
        @click="handleFeaturedItemClick(fields.featuredItemCta.value.text)"
      />
    </div>
  </section>
</template>

<script setup>
import { inject } from 'vue';
import { Text as ScText } from '@sitecore-jss/sitecore-jss-vue';
import BaseImage from '../../2_elements/BaseImage/BaseImage.vue';
import BaseButton from '../../2_elements/BaseButton/BaseButton.vue';

const { handleFeaturedItemClick } = inject('dataLayerFunctions');

defineProps({
  fields: {
    type: Object,
  },
  numOfColumns: {
    type: Number,
    default: 1,
  },
});
</script>
<style lang="scss">
@import './scss/FeaturedItem.scss';
</style>
