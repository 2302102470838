<template>
  <div>
    <button
      ref="openMenuElement"
      data-test-id="open-mobile-menu-btn"
      class="mobile-menu__btn"
      :aria-expanded="isMobileMenuVisible"
      :aria-label="t('open-mobile-menu')"
      :inert="isMobileMenuVisible"
      @click="openMobileMenu"
    >
      <i class="icon icon-Menu" aria-hidden="true"></i>
    </button>
    <transition
      name="mobile-menu-wrapper-transition"
      @enter="loadNavigationLogicOnMounted"
      @after-enter="focusOnCloseElement"
    >
      <div
        v-if="isMobileMenuVisible"
        ref="mobileMenu"
        :aria-modal="isMobileMenuVisible"
        :role="isMobileMenuVisible ? 'dialog' : null"
        class="mobile-menu"
        :class="{ 'mobile-menu--editor': isEditorActive() }"
        @keyup.esc.prevent="closeMobileMenu"
        @keydown.tab="switchTabHandler"
      >
        <button
          ref="closeMenuElement"
          data-test-id="close-mobile-menu-btn"
          class="mobile-menu__btn mobile-menu__close-btn mobile-menu__first-tab-element"
          :aria-label="t('close-mobile-menu')"
          @click="closeMobileMenu"
        >
          <i class="icon icon-Close" aria-hidden="true"></i>
        </button>

        <mobile-menu-header :fields="mobileMenuHeaderFields" @close-mobile-menu="closeMobileMenu" />
        <mobile-menu-main :fields="mobileMenuMainFields" :booking-options="bookingOptions" />
        <mobile-menu-footer v-if="brandName !== 'PubPartners'" :fields="mobileMenuFooterFields" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watchEffect, watch, onBeforeUnmount, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-vue';
import MobileMenuHeader from './MobileMenuHeader/MobileMenuHeader.vue';
import MobileMenuMain from './MobileMenuMain/MobileMenuMain.vue';
import MobileMenuFooter from './MobileMenuFooter/MobileMenuFooter.vue';
import useMedia from '../../../utils/useMedia';
import { useAppStore } from '../../../stores/appStore';

const emit = defineEmits(['mobile-menu-open']);

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  bookingOptions: {
    type: Object,
    default: () => ({}),
  },
});

const jssStore = inject('jssStore');
const brandName = jssStore?.sitecoreContext()?.brandName;

const appStore = useAppStore();
const { t } = useI18n();

const mobileMenuHeaderFields = {
  mobileOpenLogo: props.fields?.mobileOpenLogo,
  logoLink: props.fields?.link,
};

const mobileMenuMainFields = {
  navItems: props.fields?.navItems,
  ctaBookTable: props.fields?.ctaBookTable,
  ctaHeaderLink: props.fields?.ctaHeaderLink,
};
const { venuePhoneNumber, venueLatitude, venueLongitude } = props.fields;
const mobileMenuFooterFields = {
  venuePhoneNumber,
  venueLatitude,
  venueLongitude,
};

const { isMedia: isDesktop } = useMedia('(min-width: 1024px)');

const isMobileMenuVisible = ref(false);
const setMobileMenuVisible = (state) => {
  isMobileMenuVisible.value = state;
};

const mobileMenu = ref(null);
const openMenuElement = ref(null);
const closeMenuElement = ref(null);
const lastFocusableElement = ref(null);
const closeMenuTimeout = ref(null);

const switchTabHandler = (event) => {
  if (!isMobileMenuVisible.value) return;
  const { shiftKey, target } = event;
  if (shiftKey && target === closeMenuElement.value) {
    event.preventDefault();
    lastFocusableElement.value.focus();
  }
  if (!shiftKey && target == lastFocusableElement.value) {
    event.preventDefault();
    closeMenuElement.value.focus();
  }
};

const loadNavigationLogicOnMounted = () => {
  if (!mobileMenu.value) return;
  lastFocusableElement.value = [...mobileMenu.value.querySelectorAll('a')]?.at(-1);
};

const openMobileMenu = () => {
  setMobileMenuVisible(true);
  emit('mobile-menu-open');
};

const closeMobileMenu = () => {
  setMobileMenuVisible(false);

  closeMenuTimeout.value = setTimeout(() => {
    openMenuElement.value.focus();
  }, 100);
};

const focusOnCloseElement = () => {
  closeMenuElement.value.focus();
};

watchEffect(() => isMobileMenuVisible.value && isDesktop.value && setMobileMenuVisible(false));
if (!isServer()) {
  watch(mobileMenu, (newNode) => {
    appStore.disableScrollOnBody(!!newNode);
  });
}

onBeforeUnmount(() => {
  if (closeMenuTimeout.value) clearTimeout(closeMenuTimeout.value);
});

defineExpose({ mobileMenu, closeMenuElement, openMenuElement, closeMobileMenu });
</script>
<style lang="scss">
@import './scss/MobileMenu.scss';
</style>
